import { PageProps } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Layout } from "../components/layout";
import { HomeNavigation } from "../components/navigation";
import { SectionWrapper, SectionRow } from "../components/section";

const StatutPage: React.FC<PageProps> = () => {

  return (
    <Layout title="Statut Fundacji" canonical="/statut/">
      <HomeNavigation />
      <Wrapper>
        <SectionWrapper>
          <SectionRow>
            <Headline>Statut Fundacji<br /> Starsza Siostra</Headline>
          </SectionRow>
        </SectionWrapper>
      </Wrapper>
      <SectionWrapper>
        <SectionRow>
          <RichTextWrapper>
            <h2>Rozdział I.</h2>
            <h2>POSTANOWIENIA OGÓLNE</h2>

            <h3>§ 1</h3>
            <ol>
              <li>Fundacja pod nazwą Fundacja Starsza Siostra zwana dalej Fundacją, ustanowiona przez Adrianę Błażej, zwaną dalej Fundatorką, aktem notarialnym nr 3442/2021 sporządzony przed zastępcą notarialnym Martyną Rzosińską, zastępcą notariusza Moniki Sucheckiej w kancelarii notarialnej przy ul. Ogrodowej 1/1 w Krakowie  w dniu 14.12.2021.</li>
              <li>Fundacja działa na podstawie obowiązującego prawa polskiego oraz niniejszego statutu.</li>
              <li>Fundacja posiada osobowość prawną.</li>
            </ol>

            <h3>§ 2</h3>
            <ol>
              <li>Siedzibą Fundacji jest Kraków.</li>
              <li>Czas trwania fundacji jest nieoznaczony.</li>
              <li>Terenem działania Fundacji jest cały obszar Rzeczpospolitej Polskiej, przy czym w zakresie niezbędnym dla właściwego realizowania celów, Fundacja może prowadzić działalność także poza granicami Rzeczypospolitej Polskiej.</li>
              <li>Fundacja może używać wyróżniającego ją znaku graficznego, symboli, pieczęci według wzoru, które ustala Zarząd oraz odpowiedników swojej nazwy w językach obcych.</li>
              <li>Fundacja jest apolityczna i nie jest związana z żadnym wyznaniem.</li>
              <li>Fundacja może należeć do innych krajowych i międzynarodowych organizacji oraz zrzeszeń organizacji o podobnych celach i profilu działania.</li>
              <li>Na terenie Rzeczypospolitej Polskiej Fundacja może tworzyć oddziały oraz inne jednostki organizacyjne, zaś za granicą dostosuje się do obowiązującego w danym kraju prawa</li>
            </ol>

            <h2>Rozdział II.</h2>
            <h2>CELE I FORMY DZIAŁANIA FUNDACJI</h2>

            <h3>§ 3.</h3>
            <p>Cele Fundacji przedstawiają się następująco:</p>

            <ol>
              <li>Wspieranie ochrony zdrowia, propagowanie zdrowego stylu życia oraz działań mających na celu edukację, rehabilitację, terapię psychologiczną i psychiatryczną;</li>
              <li>Wspieranie  i upowszechnianie kultury fizycznej;</li>
              <li>Opieka i pomoc osobom z problemami i zaburzeniami psychologicznymi i trudnościami natury emocjonalnej;</li>
              <li>Wspieranie i rozwój psychoedukacji młodzieży;</li>
              <li>Wspieranie działań mających na celu przeciwdziałanie  uzależnieniom i patologiom społecznym;</li>
              <li>Aktywizacja i integracja społeczna oraz zawodowa osób zagrożonych i/lub dotkniętych wykluczeniem społecznym oraz pozostających bez pracy oraz zagrożonych zwolnieniem z pracy;</li>
              <li>Aktywizacja i integracja międzypokoleniowa, międzynarodowa i międzykulturowa;</li>
              <li>Upowszechnianie i ochrona wolności, praw człowieka oraz wspieranie i rozwój działań na rzecz równych praw kobiet i mężczyzn;</li>
              <li>Upowszechnianie działań wspomagających rozwój demokracji;</li>
              <li>Wspomaganie rozwoju wspólnot, społeczności lokalnych i inicjatyw oddolnych;</li>
              <li>Wspieranie i rozwój nauki, szkolnictwa wyższego, edukacji, oświaty i wychowania;</li>
              <li>Wspieranie rozwoju dzieci i młodzieży, w tym wypoczynku dzieci i młodzieży</li>
              <li>Wspieranie przedsiębiorczości;</li>
              <li>Wspieranie działań na rzecz równych szans kobiet i mężczyzn na rynku pracy.</li>
              <li>Wspieranie i upowszechnianie wolontariatu oraz dobroczynności.</li>
            </ol>

            <h3>§ 4.</h3>
            <p>Cele określone w § 3 Fundacja realizuje poprzez:</p>
            <ol>
              <li>Organizację i prowadzenie wyjazdów, obozów; warsztatów i szkoleń, seminariów, konferencji, wykładów, spotkań, targów, wystaw, happeningów, performansów i innych przedsięwzięć o charakterze terapeutycznym, psychologicznym, edukacyjnym, informacyjnym i popularyzatorskim;</li>
              <li>Prowadzenie i organizowanie działalności coachingowej na rzecz wzmacniania/ poszerzania kompetencji społecznych;</li>
              <li>Organizowanie i prowadzenie działań terapeutycznych oraz grup wsparcia,</li>
              <li>Prowadzenie i wspieranie aktywizacji i doradztwa zawodowego;</li>
              <li>Zakładanie i prowadzenie ośrodków zajmujących się terapią i edukacją;</li>
              <li>Nawiązywanie kontaktów oraz współpracy z ośrodkami w kraju i za granicą, realizującymi zbliżone do Fundacji cele;</li>
              <li>Organizowanie akcji charytatywnych, wolontariatu, pośrednictwa wolontariatu oraz wsparcia finansowego i rzeczowego dla osób i instytucji;</li>
              <li>Prowadzenie działalności naukowej i badawczej;</li>
              <li>Tworzenie aplikacji mobilnych, strony internetowej i innej działalności w Internecie;</li>
              <li>Nagrywanie materiałów video, audio, prowadzenie spotkań online, wydawanie i publikacja materiałów informacyjnych, edukacyjnych w formie elektronicznej i drukowanej;</li>
              <li>Nagrywanie i upowszechnianie nagrywanych materiałów video i audio</li>
              <li>Prowadzenie kampanii społecznych i informacyjnych;</li>
              <li>Współpracę w Polsce i za granicą z władzami samorządowymi, rządowymi organizacjami pozarządowymi oraz instytucjami publicznymi i prywatnymi w zakresie wymienionym w celach działania Fundacji.</li>
            </ol>

            <h3>§ 5.</h3>
            <p>Działalność statutowa Fundacji może być prowadzona jako działalność nieodpłatna lub jako działalność odpłatna pożytku publicznego, w rozumieniu przepisów ustawy o działalności pożytku publicznego i wolontariacie.</p>

            <h2>Rozdział III.</h2>
            <h2>MAJĄTEK I DOCHODY FUNDACJI</h2>

            <h3>§ 6.</h3>
            <p>Majątek Fundacji stanowi fundusz założycielski w kwocie 5000 zł (pięć tysięcy złotych) oraz inne mienie nabyte przez Fundację w toku działania.</p>

            <h3>§ 7.</h3>
            <ol>
              <li>
                Środki na realizację celów Fundacji i pokrycie kosztów jej działalności mogą pochodzić z:
                <ol>
                  <li>darowizn, spadków, zapisów, papierów wartościowych oraz rzeczy ruchomych i nieruchomości przekazanych na rzecz Fundacji przez osoby prawne i fizyczne</li>
                  <li>dotacji, grantów i subwencji od osób prawnych,</li>
                  <li>zbiórek publicznych, loterii i aukcji,</li>
                  <li>majątku fundacji, w tym funduszu założycielskiego,</li>
                  <li>odsetek, depozytów i lokat bankowych,</li>
                  <li>wpływów działalności odpłatnej pożytku publicznego,</li>
                  <li>jednostek uczestnictwa w spółkach prawa handlowego (udziałów, akcji, ogółu praw i obowiązków w spółkach osobowych prawa handlowego).</li>
                </ol>
              </li>
              <li>Darowizny, spadki i zapisy mogą być użyte na realizację wszystkich celów Fundacji, o ile ofiarodawcy nie postanowili inaczej, jednakże postanowienie ofiarodawców nie może być sprzeczne z celami statutowymi Fundacji.</li>
              <li>W przypadku powołania Fundacji do dziedziczenia, Zarząd składając oświadczenie o przyjęciu spadku może dokonać wyłącznie przyjęcia spadku z dobrodziejstwem inwentarza.</li>
              <li>Majątek fundacji oraz jej przychody mogą być przeznaczane wyłącznie na realizację celów statutowych.</li>
              <li>Rokiem obrotowym jest rok kalendarzowy, z wyjątkiem pierwszego roku działalności Fundacji, który kończy się z dniem 31.12.2022 roku.</li>
            </ol>

            <h2>Rozdział IV.</h2>
            <h2>WŁADZE FUNDACJI</h2>

            <h3>§ 8.</h3>
            <p>Jedyną władzą Fundacji jest Zarząd Fundacji, zwany dalej Zarządem.</p>

            <h2>Zarząd Fundacji</h2>

            <h3>§ 9.</h3>
            <ol>
              <li>W skład Zarządu Fundacji może wchodzić od 1 do 6 osób.</li>
              <li>Fundatorka powołuje Zarząd, wchodzi w jego skład i pełni funkcję Prezeski Zarządu. W razie gdy mandat Fundatorki w Zarządzie Fundacji wygaśnie lub przestanie ona pełnić w Zarządzie funkcję Prezeski Zarządu, uprawnienie do powierzania funkcji Prezeski lub Prezesa Zarządu innej osobie i pozbawiania tej funkcji przysługiwać będzie Fundatorce.</li>
              <li>Członkowie i Członkinie Zarządu powołani są bezterminowo.</li>
              <li>
                Członkostwo w Zarządzie ustaje na skutek:
                <ol>
                  <li>złożenia pisemnej rezygnacji na ręce jednego z członków Zarządu lub Fundatorki,</li>
                  <li>utraty praw publicznych na skutek skazania prawomocnym wyrokiem sądu,</li>
                  <li>śmierci członka Zarządu,</li>
                  <li>odwołania przez Fundatorkę.</li>
                </ol>
              </li>
            </ol>

            <h3>§ 10.</h3>
            <ol>
              <li>Członkowie i Członkinie  Zarządu mogą otrzymywać wynagrodzenie o ile Zarząd podejmie stosowną uchwałę o przyznawaniu i wysokości wynagrodzenia,</li>
              <li>Członkowie i Członkinie  Zarządu mogą pozostawać z Fundacją w stosunku pracy.</li>
            </ol>

            <h3>§ 11.</h3>
            <ol>
              <li>Zarząd kieruje działalnością Fundacji i reprezentuje ją na zewnątrz.</li>
              <li>
                Do zakresu działania Zarządu należy w szczególności:
                <ol>
                  <li>wytyczanie głównych kierunków działalności Fundacji,</li>
                  <li>kierowanie bieżącą działalnością Fundacji,</li>
                  <li>realizacja celów statutowych,</li>
                  <li>sporządzanie planów pracy i budżetu,</li>
                  <li>sprawowanie zarządu nad majątkiem Fundacji,</li>
                  <li>zatrudnianie pracowników i ustalanie wysokości ich wynagrodzenia,</li>
                </ol>
              </li>
              <li>Zarząd co roku zobowiązany jest sporządzać roczne sprawozdanie z działalności Fundacji oraz zapewniać sporządzenie rocznego sprawozdania finansowego Fundacji.</li>
            </ol>
            <h3>§ 12.</h3>
            <ol>
              <li>Posiedzenia Zarządu odbywają się w miarę potrzeb, nie rzadziej jednak niż raz do roku.</li>
              <li>Zarząd podejmuje decyzje za pomocą uchwał, zwykłą większością głosów oddanych, przy obecności co najmniej połowy swoich członków i/lub członkiń.</li>
              <li>Posiedzenia Zarządu zwołuje jeden z członków lub jedna z członkiń Zarządu zawiadamiając wszystkich członków i/lub członkinie Zarządu, za pomocą listów poleconych lub przesyłek nadanych pocztą kurierską, wysłanych co najmniej tydzień przed terminem posiedzenia.</li>
              <li>Dopuszczalne jest dokonanie zwołania poprzez wysłanie członkowi lub członkini Zarządu, co najmniej trzy dni przed terminem posiedzenia, zaproszenia pocztą elektroniczną, jeżeli uprzednio wyraził pisemną zgodę na dokonywanie wobec niego lub wobec niej korespondencji w tej formie, podając adres, na który korespondencja powinna być wysyłana.</li>
              <li>W zaproszeniu należy oznaczyć dzień, godzinę i miejsce posiedzenia oraz porządek obrad. Regulamin Zarządu może zawierać określenie dodatkowych sposobów zwoływania posiedzeń Zarządu.</li>
              <li>O ile niniejszy statut nie stanowi inaczej, Zarząd może podejmować uchwały bez odbycia posiedzenia, w drodze głosowania pisemnego, głosowania za pośrednictwem poczty elektronicznej lub głosowania przy wykorzystaniu środków bezpośredniego porozumiewania się na odległość.</li>
              <li>Uchwały podejmowane poza posiedzeniem zapadają zwykłą większością głosów oddanych.</li>
              <li>Uchwała Zarządu podjęta poza posiedzeniem jest ważna, jeżeli wszyscy członkowie i/lub członkinie Zarządu zostali powiadomieni o treści projektu uchwały.</li>
              <li>Posiedzeniom Zarządu przewodniczy Prezeska Zarządu, a w razie jej nieobecności przewodniczący lub przewodnicząca posiedzenia Zarządu, którego lub którą wybierają obecni lub obecne na posiedzeniu członkowie i/lub członkinie Zarządu.</li>
              <li>Z posiedzeń Zarządu sporządza się protokół, który podpisuje osoba odpowiedzialna za jego sporządzenie oraz przewodniczący lub przewodnicząca posiedzenia Zarządu. Do protokołu dołącza się listę obecności.</li>
            </ol>

            <h3>Sposób reprezentacji oraz zaciągania zobowiązań majątkowych</h3>

            <h3>§ 13.</h3>
            Reprezentowanie Fundacji na zewnątrz  może wykonywać każdy członek lub członkini Zarządu samodzielnie, z tym zastrzeżeniem że w przypadku gdy Zarząd liczy co najmniej dwóch członków i/lub członkiń, rozporządzenie prawem lub zaciągnięcie zobowiązania do świadczenia o wartości przewyższającej kwotę 15 000,00 zł (piętnaście tysięcy złotych) wymaga współdziałania co najmniej dwóch członków lub członkiń Zarządu.

            <h3>Prowadzenie spraw Fundacji</h3>

            <h3>§ 14.</h3>
            <ol>
              <li>Każdy członek i/lub każda członkini Zarządu ma prawo i obowiązek prowadzenia spraw Fundacji.</li>
              <li>Każdy członek i/lub każda członkini Zarządu może prowadzić bez uprzedniej uchwały Zarządu sprawy nieprzekraczające zakresu zwykłych czynności Fundacji, jeżeli jednak przed załatwieniem sprawy nieprzekraczającej zakresu zwykłych czynności Fundacji choćby jeden z pozostałych członków lub członkiń Zarządu sprzeciwi się jej przeprowadzeniu lub jeżeli sprawa przekracza zakres zwykłych czynności Fundacji, wymagana jest uprzednia uchwała Zarządu.</li>
              <li>
                Za czynności przekraczające zakres zwykłych czynności Fundacji uważa się w szczególności:
                <ol>
                  <li>nabycie oraz zbycie nieruchomości lub prawa użytkowania wieczystego,</li>
                  <li>ustanowienie ograniczonego prawa rzeczowego,</li>
                  <li>zawarcie umowy kredytu albo pożyczki,</li>
                  <li>przejęcie długu, uznanie długu, zwolnienie z długu, przystąpienie do długu,</li>
                  <li>zawarcie umowy poręczenia lub zawarcie innej podobnej umowy.</li>
                </ol>
              </li>
            </ol>

            <h2>Rozdział. V.</h2>
            <h2>POSTANOWIENIE KOŃCOWE</h2>

            <h3>§ 15.</h3>

            <p>Zmiana statutu może dotyczyć wszystkich zapisów umieszczonych w statucie, w tym: powołania nowych organów Fundacji i zmiany celów Fundacji. Zmiana statutu Fundacji wymaga uchwały podjętej na posiedzeniu Zarządu, bezwzględną większością głosów oddanych przy obecności co najmniej połowy członków i/lub członkiń Zarządu.</p>

            <h3>§ 16.</h3>
            <ol>
              <li>Fundacja może się połączyć z inną fundacją dla efektywnego realizowania swoich celów.</li>
              <li>Połączenie z inną fundacją nie może nastąpić, jeżeli w jego wyniku mógłby ulec istotnej zmianie cel Fundacji.</li>
              <li>W sprawach połączenia z inną fundacją decyzję podejmuje Zarząd uchwałą podjętą na posiedzeniu, bezwzględną większością głosów oddanych, przy obecności co najmniej połowy członków i/lub członkiń Zarządu.</li>
            </ol>

            <h3>§ 17.</h3>
            <ol>
              <li>Decyzję o otwarciu likwidacji Fundacji podejmuje Zarząd uchwałą podjętą na posiedzeniu, bezwzględną większością głosów oddanych, przy obecności co najmniej połowy członków i/lub członkiń Zarządu.</li>
              <li>Decyzja o otwarciu likwidacji Fundacji może być podjęta wyłącznie w razie zaistnienia przesłanek jej podjęcia określonych przepisami prawa.</li>
              <li>W przypadku podjęcia uchwały o otwarciu likwidacji, likwidatorami lub likwidatorkami Fundacji, o ile uchwała ta nie stanowi inaczej, stają się członkowie lub członkinie Zarządu.</li>
              <li>O likwidacji Fundacji Zarząd zawiadamia ministra właściwego do spraw Fundacji.</li>
              <li>Likwidatorzy powinni zakończyć bieżące sprawy Fundacji, ściągnąć wierzytelności i wypełnić zobowiązania Fundacji.</li>
              <li>Środki finansowe i majątek pozostały po likwidacji Fundacji mogą zostać przeznaczone mocą uchwały na rzecz działających w RP organizacji pozarządowych o zbliżonych celach.</li>
            </ol>

            <h3>§ 18.</h3>
            <p>W razie śmierci Fundatorki albo utraty przez nią zdolności do czynności prawnych kompetencje zastrzeżone niniejszym Statutem wyłącznie dla Fundatorki należeć będą do Zarządu, o ile Fundatorka nie wskaże osoby uprawnionej do wykonywania tych kompetencji. Oświadczenie Fundatorki o wskazaniu osoby uprawnionej, o którym mowa w zdaniu poprzednim, wymaga zachowania formy pisemnej z podpisem notarialnie poświadczonym, pod rygorem nieważności.</p>
          </RichTextWrapper>
        </SectionRow>
      </SectionWrapper>
    </Layout>
  );
};

const Wrapper = styled.div.attrs({ className: "bg-primary" })``;

const Headline = styled.h1.attrs({ className: "font-bold text-white text-6xl mb-8 md:text-8xl" })``;

const RichTextWrapper = styled.div.attrs({ className: "text-xl text-justify select-none" })`
  h2, h3 {
    font-weight: bold;
    text-align: center;
    margin: 3rem 0;
    font-size: 1.5rem;
  }

  ol {
    list-style-type: decimal;
    margin-left: 3rem;

    li {
      margin-bottom: 0.5rem;
    }
    
    ol {
      list-style-type: lower-alpha;
      margin-top: 0.5rem;
    }
  }

  p {
    margin-bottom: 1rem;
  }
`;

export default StatutPage;